import React from 'react';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PauseRounded from '@mui/icons-material/PauseRounded';
import PlayArrowRounded from '@mui/icons-material/PlayArrowRounded';

interface PlayButtonProps {
  onClick: () => void;
  isPlaying: boolean;
}

const playStyles = {
  fontSize: '3rem',
  border: '1px solid white',
  borderRadius: '50%',
  fill: 'white',
};

const PlayButton: React.FC<PlayButtonProps> = ({ onClick, isPlaying }) => {
  // TODO:  Play button lacks proper graphic as per design
  return (
    <Container maxWidth="sm">
      <Box textAlign="center">
        <IconButton
          aria-label={isPlaying ? 'pause' : 'play'}
          onClick={() => onClick()}
        >
          {isPlaying ? (
            <PauseRounded sx={playStyles} />
          ) : (
            <PlayArrowRounded sx={playStyles} />
          )}
        </IconButton>
      </Box>
    </Container>
  );
};

export default PlayButton;
