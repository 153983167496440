import { useEffect, useState, createContext, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getFromLocalStorage, setInLocalStorage } from '../utils/localStorage';
import { isLanguage, isPlatform, Language, Platform } from '../utils/types';

interface AppData {
  language: Language;
  platform: Platform;
}

const AppContext = createContext<AppData>({
  language: 'de',
  platform: '20min-de',
});

const AppContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [language, setLanguage] = useState<Language>('de');
  const [platform, setPlatform] = useState<Platform>('20min-de');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const languageParam = searchParams.get('language');
    const platformParam = searchParams.get('platform');
    const languageFromLocalStorage = getFromLocalStorage('language');
    const platformFromLocalStorage = getFromLocalStorage('platform');

    if (isLanguage(languageParam)) {
      setLanguage(languageParam);
      setInLocalStorage('language', languageParam);
    } else if (isLanguage(languageFromLocalStorage)) {
      setLanguage(languageFromLocalStorage);
    } else {
      setLanguage('de');
    }

    if (isPlatform(platformParam)) {
      setPlatform(platformParam as Platform);
      setInLocalStorage('platform', platformParam);
    } else if (isPlatform(platformFromLocalStorage)) {
      setPlatform(platformFromLocalStorage);
    } else {
      setPlatform('20min-de');
    }
  }, [searchParams]);

  return (
    <AppContext.Provider value={{ language, platform }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);

export default AppContextProvider;
