import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import IconButton from '@mui/material/IconButton';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

interface VolumeSliderProps {
  audioRef: React.MutableRefObject<HTMLAudioElement>;
}

const VolumeSlider: React.FC<VolumeSliderProps> = ({ audioRef }) => {
  const [volume, setVolume] = useState(1);

  // TODO: could be nice to store previous volume in cookies or localeStorage
  const handleVolumeChange = (event: Event) => {
    const newVolume = parseFloat((event.target as HTMLInputElement).value);
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
  };

  const handleMin = () => {
    setVolume(0);
    audioRef.current.volume = 0;
  };

  const handleMax = () => {
    setVolume(1);
    audioRef.current.volume = 1;
  };

  // TODO: Volume Icons do not match design but are maybe ok, ask Christoph
  return (
    <Container maxWidth="xs">
      <Box textAlign="center">
        <Grid container spacing={2} display="flex">
          <Grid item xs={2} textAlign="center" alignSelf="center">
            <IconButton onClick={handleMin}>
              <VolumeDownIcon sx={{ fill: 'white' }} />
            </IconButton>
          </Grid>
          <Grid item xs={8} textAlign="center" alignSelf="center">
            <CustomSlider
              aria-label="Volume"
              value={volume}
              onChange={handleVolumeChange}
              min={0}
              max={1}
              step={0.01}
              sx={{ marginTop: '5px' }}
            />
          </Grid>
          <Grid item xs={2} textAlign="center" alignSelf="center">
            <IconButton onClick={handleMax}>
              <VolumeUpIcon sx={{ fill: 'white' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default VolumeSlider;

const CustomSlider = styled(Slider)({
  color: '#c929ff',
  lineHeight: 0,
  '& .MuiSlider-rail': {
    backgroundColor: '#fff',
  },
  '& .MuiSlider-thumb': {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    '&:hover, &.Mui-focusVisible': {
      boxShadow: '0px 0px 0px 8px rgb(0 0 0 / 4%)',
    },
    '&.Mui-active': {
      boxShadow: '0px 0px 0px 14px rgb(0 0 0 / 4%)',
    },
  },
  '&.MuiSlider-markActive': {
    opacity: 1,
  },
});
