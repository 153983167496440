import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

import useCurrentTrackData from '../../hooks/useCurrentTrackData';

dayjs.extend(duration);

interface TimeSliderProps {
  isPlaying: boolean;
}

const TimeSlider: React.FC<TimeSliderProps> = ({ isPlaying }) => {
  const { data } = useCurrentTrackData();
  const [fakeLoading, setFakeLoading] = useState(false);

  useEffect(() => {
    // We could potentially improve this, by hooking into AUDIO API?
    if (isPlaying === true) {
      setFakeLoading(true);
      setTimeout(() => {
        setFakeLoading(false);
      }, 2000);
    }
  }, [isPlaying]);

  if (!data) return null;

  const currentTrack = data.track.playing;

  const start = dayjs.unix(currentTrack.timestamp_start);
  const serverTime = dayjs.unix(data?.serverTime);

  const duration = dayjs.duration(currentTrack.trackDuration, 'second');
  const timePassed = dayjs.duration(serverTime.diff(start));

  return data ? (
    <Box pl={{ md: 4 }} pr={{ md: 4 }} pt={1}>
      <Grid container padding={0}>
        <Grid item xs={12}>
          <Box sx={{ height: '41px' }}>
            <CustomSlider
              aria-label="Time"
              min={isPlaying && !fakeLoading ? currentTrack.timestamp_start : 0}
              max={isPlaying && !fakeLoading ? currentTrack.timestamp_end : 1}
              value={isPlaying && !fakeLoading ? data.serverTime : 0}
              disabled
            />
          </Box>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'left' }}>
          <TimeUnit>
            {isPlaying && !fakeLoading
              ? timePassed.format('m:ss')
              : fakeLoading
              ? 'Loading...'
              : '00:00'}
          </TimeUnit>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right' }}>
          <TimeUnit>
            {isPlaying && !fakeLoading ? duration.format('m:ss') : ''}
          </TimeUnit>
        </Grid>
      </Grid>
    </Box>
  ) : null;
};

export default TimeSlider;

const CustomSlider = styled(Slider)({
  '&.Mui-disabled': {
    color: '#C929FF',
    lineHeight: 0,
    marginTop: '4px',
    '& .MuiSlider-rail': {
      backgroundColor: '#fff',
    },
    '& .MuiSlider-thumb': {
      height: 12,
      width: 12,
      backgroundColor: '#C929FF',
      '&:hover, &.Mui-focusVisible': {
        boxShadow: '0px 0px 0px 8px rgb(0 0 0 / 4%)',
      },
    },
  },
});

const TimeUnit = styled(Typography)({
  color: '#0D7780',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '12px',
});
