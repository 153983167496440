import React from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import LiveOrPreview from './LiveOrPreview';
import useCurrentTrackData from '../../hooks/useCurrentTrackData';

const PlayerData: React.FC = () => {
  const { data, error, isLoading } = useCurrentTrackData();

  // TODO: could benefit from prettier loading and error display
  if (error) return <div>Failed to load</div>;
  if (isLoading) return <div>Loading...</div>;

  const currentTrack = data?.track.playing;
  const adText = 'Greatest Hits Of All Time';

  const isAdvert = currentTrack?.trackArtist === '20 Minuten Radio';

  return data ? (
    <Stack spacing={2} direction={{ xs: 'row', md: 'column' }}>
      <CoverImage>
        {isAdvert ? (
          <img src={'/img/goat-radio.png'} alt={adText} />
        ) : (
          <img
            src={currentTrack?.trackAlbumCover}
            alt={currentTrack?.trackTitle}
          />
        )}
      </CoverImage>
      <Box pl={{ md: 4 }} pr={{ md: 4 }} pt={1}>
        <LiveOrPreview />
        <Box sx={{ height: 44 }}>
          {isAdvert ? (
            <>
              <Typography
                sx={{
                  color: '#C929FF',
                  fontWeight: 900,
                  fontSize: '22px',
                  lineHeight: '24px',
                }}
              >
                {adText}
              </Typography>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  color: '#C929FF',
                  fontWeight: 900,
                  fontSize: '16px',
                  lineHeight: '20px',
                }}
              >
                {currentTrack?.trackArtist}
              </Typography>
              <Typography
                sx={{
                  color: '#0D7780',
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '24px',
                }}
              >
                {currentTrack?.trackTitle}
              </Typography>
            </>
          )}
        </Box>
      </Box>
    </Stack>
  ) : null;
};

export default PlayerData;

const CoverImage = styled('div')(({ theme }) => ({
  width: 100,
  height: 100,
  objectFit: 'cover',
  overflow: 'hidden',
  flexShrink: 0,
  borderRadius: 15,
  border: '1px solid #FFFFFF',
  '& > img': {
    width: '100%',
  },
  [theme.breakpoints.up('md')]: {
    width: 200,
    height: 200,
    margin: '0 auto',
  },
}));
