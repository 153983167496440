import React from 'react';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { getConfig } from '../utils/config';
import { useAppContext } from '../context/AppContext';

// TODO: Implement tabs
const Header: React.FC = () => {
  const { language } = useAppContext();
  const config = getConfig(language);

  if (!config.headerEnabled) {
    return null;
  }

  return (
    <Grid
      item
      xs={12}
      padding={2}
      sx={{ backgroundColor: '#c929ff', borderBottom: '1px solid white' }}
    >
      <Link to="/">Radio</Link> | <Link to="/podcast">Podcasts</Link>
    </Grid>
  );
};

export default Header;
