import React from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useAppContext } from '../context/AppContext';
import { AdsAccountNames } from '../utils/const';
import useHistoryData from '../hooks/useHistoryData';
import Ad from './Ad';
import HistoryTrackDivider from './HistoryTrackDivider';

dayjs.extend(customParseFormat);

const History: React.FC = () => {
  const { data, error, isLoading } = useHistoryData();
  const { language } = useAppContext();
  const { pathname } = useLocation();

  // TODO: could benefit from prettier loading and error display
  if (error) return <div>Failed to load</div>;
  if (isLoading) return <div>Loading...</div>;

  let adCounter = 0;

  // Init the Ad code, this will run every time the history data changes
  // It works similar to a route change, the ad containers are added again and new ads appear
  try {
    window.TATM.push(() => {
      window.TATM.init(AdsAccountNames[language], pathname);
    });
  } catch (error) {
    console.warn(`[TATM] unhandled error on "init":`, error);
  }

  return !!data?.length ? (
    <>
      {data.map((track, i) => {
        // Show first ad after 3 songs
        const showFirstAd = i === 2;
        // Then show an ad every 6, offset modules by -2
        const showAd = i > 2 && (i - 2) % 6 === 0;

        if (showFirstAd || showAd) {
          adCounter += 1;
        }

        // TODO: We should add a small PLAY icon on the cover image on hover, then allow the user to play
        // TODO: the track preview instead of live radio

        return (
          <Box key={track.id}>
            <Grid container spacing={2} padding={2}>
              <Grid item xs="auto">
                <Typography
                  sx={{
                    color: '#FFF',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '20px',
                    opacity: 0.75,
                  }}
                >
                  &#x2022; {dayjs(track.trackStart, 'HH:mm:ss').format('HH:mm')}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  sx={{
                    color: '#FFF',
                    fontWeight: 900,
                    fontSize: '16px',
                    lineHeight: '20px',
                  }}
                >
                  {track.trackTitle}
                </Typography>
                <Typography
                  sx={{
                    color: '#FFF',
                    fontWeight: 400,
                    fontSize: '14px',
                    lineHeight: '20px',
                    opacity: 0.75,
                  }}
                >
                  {track.trackArtist}
                </Typography>
              </Grid>
              <Grid item xs="auto">
                <CoverImage>
                  <img src={track.trackAlbumCover} alt={track.trackTitle} />
                </CoverImage>
              </Grid>
            </Grid>
            <HistoryTrackDivider />
            {(showFirstAd || showAd) && <Ad adCounter={adCounter} />}
          </Box>
        );
      })}
    </>
  ) : null;
};

export default History;

const CoverImage = styled('div')({
  width: 45,
  height: 45,
  objectFit: 'cover',
  overflow: 'hidden',
  flexShrink: 0,
  borderRadius: 5,
  '& > img': {
    width: '100%',
  },
});
