import { base64DecodeUrlSafe } from './string';

function parseCookieJson(cookie: string) {
  try {
    return JSON.parse(base64DecodeUrlSafe(cookie));
  } catch (error) {
    console.error('[Cookie]Error while parsing cookie json', {
      error: error,
    });
  }
}

/**
 * Return the value of a cookie
 * @param {string} cookie name
 * @param {string} [cookies] list of cookies (server side request or browser, defaults to document.cookie)
 * @returns {string}
 */
export function getCookie(name: string, cookies?: string) {
  let cookiesStr = cookies;

  if (!cookies) {
    try {
      cookiesStr = document.cookie;
    } catch (error) {
      console.error('[Cookie]Error while accessing cookies', {
        error: error,
      });
      return;
    }
  }

  const matches = cookiesStr
    ? cookiesStr.match('(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)')
    : '';

  if (matches) {
    return decodeURIComponent(matches.pop() || '');
  }
}

/**
 * Return JSON stored in a url safe base64 encoded cookie
 * @param {string} cookie name
 * @param {string} [cookies] list of cookies (server side request or browser, defaults to document.cookie)
 * @returns {any}
 */
export function getCookieJson<T>(name: string, cookies?: string): T | void {
  var cookie = getCookie(name, cookies);
  if (cookie) {
    return parseCookieJson(cookie);
  }
}
