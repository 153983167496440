export const base64Encode = (input: string) => btoa(input);

export const base64Decode = (input: string) => atob(input);

/**
 * Take a url safe base64 encoded string and transform it
 * into a standard base64 encoded string before passing it
 * to the base64Decode function.
 */
export const base64DecodeUrlSafe = function base64DecodeUrlSafe(input: string) {
  const base64String = (input + '==='.slice((input.length + 3) % 4))
    .replace(/-/g, '+')
    .replace(/_/g, '/');

  return base64Decode(base64String);
};
