import { Helmet } from 'react-helmet';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';

import { getConfig } from '../utils/config';
import { globalStyles } from '../utils/const';
import { useAppContext } from '../context/AppContext';

const Global = () => {
  const { language } = useAppContext();
  const config = getConfig(language);

  return (
    <>
      <Helmet htmlAttributes={{ lang: language }}>
        <title>{config.meta.title}</title>
        <meta name="description" content={config.meta.description} />
        <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          data-domain-script={config.cmpDomain}
          data-document-language="true"
        />
        <link rel="stylesheet" href={config.fontStylesheet} />
      </Helmet>
      <CssBaseline />
      <GlobalStyles styles={globalStyles} />
    </>
  );
};

export default Global;
