import { Language } from './types';

const getEnvironment = () =>
  window.location.host === 'audio.20min.ch' ? 'production' : 'staging';

interface Config {
  headerEnabled: boolean;
  cmpDomain: string;
  fontStylesheet: string;
  cookie: {
    userSession: string;
  };
  meta: {
    title: string;
    description: string;
    canonicalUrl: string;
  };
}

const productionConfigDE: Config = {
  headerEnabled: false,
  cmpDomain: 'f1aeb90f-3d11-4baf-b163-9b7fc7e715cd',
  fontStylesheet: 'https://20min.ch/static-assets/batonturbo.css',
  cookie: {
    userSession: '20min-Session',
  },
  meta: {
    title: 'GOAT Radio - Greatest Hits of All Time', // TODO: do we want to have different titles for radio and podcast?
    description:
      'GOAT Radio Schweiz. Sämtliche Greatest Hits of all Time beim neuen 20 Minuten Retro Radio anhören. Frequenzen 105.0 und 93,0 (in und um Zürich)',
    canonicalUrl: 'https://audio.20min.ch', // TODO: will need to be adjusted when adding tracking to podcasts
  },
};

const productionConfigFR: Config = {
  ...productionConfigDE,
  meta: {
    ...productionConfigDE.meta,
    title: 'GOAT Radio online: écoute les hits de la musique pop rock',
    description: `Retrouve les Hits Of All Time de GOAT Radio suisse en ligne ou sur l'app 20 minutes. Diffusion de tubes pop rock des années 80 et plein d'autres chansons!`,
  },
};

const stagingConfigDE: Config = {
  ...productionConfigDE,
  cmpDomain: 'f1aeb90f-3d11-4baf-b163-9b7fc7e715cd-test',
  fontStylesheet: 'https://20min.dev/static-assets/batonturbo.css',
  meta: {
    ...productionConfigDE.meta,
    title: 'Staging - GOAT Radio - Greatest Hits of All Time',
    description:
      'Staging - GOAT Radio Schweiz. Sämtliche Greatest Hits of all Time beim neuen 20 Minuten Retro Radio anhören. Frequenzen 105.0 und 93,0 (in und um Zürich)',
    canonicalUrl: 'https://audio.20min.dev',
  },
};

const stagingConfigFR: Config = {
  ...stagingConfigDE,
  meta: {
    ...stagingConfigDE.meta,
    title:
      'Staging - GOAT Radio online: écoute les hits de la musique pop rock',
    description: `Staging - Retrouve les Hits Of All Time de GOAT Radio suisse en ligne ou sur l'app 20 minutes. Diffusion de tubes pop rock des années 80 et plein d'autres chansons!`,
  },
};

const configMapping = {
  de: {
    production: productionConfigDE,
    staging: stagingConfigDE,
  },
  fr: {
    production: productionConfigFR,
    staging: stagingConfigFR,
  },
} as const;

function getConfig(language: Language): Config {
  const environment = getEnvironment();
  const config = configMapping[language][environment];

  return config;
}

export { getConfig };
