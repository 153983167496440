import React, { useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import HistoryTrackDivider from './HistoryTrackDivider';

interface AdProps {
  adCounter: number;
}

const Ad: React.FC<AdProps> = ({ adCounter }) => {
  const container = useRef<HTMLDivElement>(null);
  const adContainer = useRef<HTMLDivElement>(null);

  // This was determined by the ad team at Goldbach
  // They plan to schedule ads with a height of 250px
  const expectedAdHeight = 250;

  useEffect(() => {
    // useEffect runs after render, so the containers will exist
    // and the ad code will run and find containers
    try {
      window.TATM.push(() => {
        window.TATM.initAdUnits([
          {
            adUnitName: `inside-pos${adCounter}/radio`,
            targetContainerId: `inside-pos${adCounter}-radio`,
            callback: (cbData) => {
              // We always render a 250px height container for the ad
              // Then manually outside of React render flow adjust its height
              // This resizes the container to fit the advert
              const ad = adContainer.current;
              const component = container.current;

              if (ad && component) {
                if (cbData.isEmpty) {
                  // No advert loaded, remove the entire container
                  component.style.display = 'none';
                } else {
                  // Resize container to match the loaded advert size
                  const adHeight = cbData.size[1];
                  ad.style.minHeight = `${adHeight}px`;
                }
              }
            },
          },
        ]);
      });
    } catch (error) {
      console.warn(
        `[TATM] unhandled error on "initAdUnits" - (inside-pos${adCounter}/radio):`,
        error,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={container}>
      <Box p={2} pb={1}>
        <div
          ref={adContainer}
          id={`inside-pos${adCounter}-radio`}
          style={{
            minHeight: `${expectedAdHeight}px`,
          }}
        />
        <Box
          sx={{
            color: '#FFF',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '24px',
            opacity: 0.75,
            textAlign: 'center',
          }}
        >
          Advert
        </Box>
      </Box>
      <HistoryTrackDivider />
    </div>
  );
};

export default Ad;
