import React, { useState, useRef } from 'react';

import VolumeSlider from './VolumeSlider';
import TimeSlider from './TimeSlider';
import PlayButton from './PlayButton';
import { LiveStreamsURLs } from '../../utils/const';
import { isIOS } from '../../utils/platform';
import {
  trackRadioPlay,
  trackRadioResume,
  trackRadioStop,
} from '../../utils/gtm';
import { useAppContext } from '../../context/AppContext';

const PlayerControls: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [wasPaused, setWasPaused] = useState(false);
  const audioRef = useRef(new Audio(''));
  const { platform, language } = useAppContext();

  const handleToggle = () => {
    // When using audioRef.current.play() and audioRef.current.pause() it was possible
    // for the audio stream to become out of sync with the data stream.
    // This approach stops the stream and starts again instead of pausing/resuming it.
    if (isPlaying) {
      audioRef.current.src = '';
      setWasPaused(true);
      trackRadioStop({ platform, language });
    } else {
      audioRef.current.src = LiveStreamsURLs[language];
      audioRef.current.load();
      audioRef.current.play();

      if (wasPaused) {
        trackRadioResume({ platform, language });
      } else {
        trackRadioPlay({ platform, language });
      }
    }
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
  };

  return (
    <>
      <TimeSlider isPlaying={isPlaying} />
      <PlayButton onClick={handleToggle} isPlaying={isPlaying}></PlayButton>
      {!isIOS() && <VolumeSlider audioRef={audioRef} />}
    </>
  );
};

export default PlayerControls;
