import React from 'react';
import Box from '@mui/material/Box';

const LiveOrPreview: React.FC = () => {
  const liveOrPreview = true;

  return (
    <Box
      display="inline-block"
      sx={{
        fontWeight: 900,
        fontSize: '14px',
        lineHeight: '20px',
        color: '#C929FF',
        background: 'rgba(255, 255, 255, 0.5)',
        borderRadius: '6px',
        padding: '2px 10px 1px',
        marginBottom: '8px',
      }}
    >
      {liveOrPreview ? 'LIVE' : 'PREVIEW'}
    </Box>
  );
};

export default LiveOrPreview;
