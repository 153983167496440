import cmpstub from '@iabtcf/stub';
import { Routes, Route, Navigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';

import AppContextProvider from './context/AppContext';
import Global from './components/Global';
import Header from './components/Header';
import Radio from './routes/radio';
import { getPXDecreasedOnIOS } from './utils/styles';
// import Podcast from './routes/podcast';

if (typeof window !== 'undefined') {
  cmpstub();
}

function App() {
  return (
    <AppContextProvider>
      <Global />
      <Grid
        container
        height={{
          xs: '100%',
          md: `${getPXDecreasedOnIOS(500)}`,
        }}
        maxWidth={1332}
        sx={{
          margin: {
            lg: '24px auto',
          },
        }}
        style={{ outline: '2px solid white' }}
      >
        <Header />
        <Routes>
          <Route index element={<Radio />} />
          {/* <Route path="podcast" element={<Podcast />} /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Grid>
    </AppContextProvider>
  );
}

export default App;
